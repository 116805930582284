@import './utils';
@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap" rel="stylesheet');
$hamburger-layer-width : 40px !default;
$hamburger-layer-height : 6px !default;
$hamburger-layer-spacing : 6px !default;
$hamburger-layer-color : $intact-red !default;
@import '~hamburgers/_sass/hamburgers/hamburgers';

@font-face {
  font-family: 'Slate Std';
  src: local('Slate Std'), url(./fonts/SlateStd.otf) format('truetype');
}

@font-face {
  font-family: 'Slate Std Bold';
  src: local('Slate Std Bold'), url(./fonts/SlateStd-Bold.otf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./fonts/Poppins-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins Bold';
  src: local('Poppins Bold'), url(./fonts/Poppins-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins SemiBold';
  src: local('Poppins SemiBold'), url(./fonts/Poppins-SemiBold.ttf) format('truetype');
}


@font-face {
  font-family: 'Poppins Black';
  src: local('Poppins Black'), url(./fonts/Poppins-Black.ttf) format('truetype');
}

.App {
  overflow-x: hidden;
  font-family: 'Poppins', sans-serif;

  //font-family: 'Slate Std', sans-serif;
  h2 {
    font-size: 30px;
    font-family: 'Poppins SemiBold';

    @media #{$sm-device} {
      font-size: 30px;
    }
  }

  p {
    font-size: 18px;
  }
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: $intact-blue;
}

.fallback-loader,
.fallback-black-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;

  &>span {
    width: 76px;
    height: 76px;

    &>span {
      margin: 0;
      border-width: 8px;

    }
  }
}

.fallback-black-loader {
  background-color: #ffffff;
}

.errorSubmit {
  color: $intact-red;
}

.container {
  @media only screen and (min-width: 1200px) {
    max-width: 1280px !important;
  }
}

/******Authenticated Menu*******************************************/
.menu-top-container {
  width: calc(100vw - 40px);
  max-width: 1280px;
  margin: 0 auto;
  //border-bottom: 1px solid #eee;

  .menu-top-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;

    .logo {
      width: 125px;
      flex: 0;

      @media #{$md-device} {
        width: 100px;
      }

      img {
        width: 100%;
      }
    }

    .hamburger {
      position: relative;
      z-index: 999;
      display: none;
      padding: 10px;

      &:focus {
        outline: none
      }

      .hamburger-box {
        @include transform(scale(0.7))
      }

      @media #{$md-device} {
        display: flex;
      }
    }

    .menu-desktop {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      align-items: center;

      @media #{$md-device} {
        display: none;
      }

      ul {
        list-style: none;
        display: flex;
        margin: 0 25px 0 0;

        li {
          margin: 0 10px;

          a {
            text-decoration: none;
            display: inline-block;
            padding: 10px 10px;
            color: #000000;
            //letter-spacing: 1.5px;
            position: relative;
            font-size: 16px;

            &:after {
              content: '';
              width: 100%;
              height: 2px;
              position: absolute;
              bottom: 0;
              left: 0;
              background-color: $intact-red;
              opacity: 0;
              @include transition(opacity 0.25s linear)
            }

            &:hover {
              cursor: pointer;

              &:after {
                opacity: 1;
              }
            }
          }

          &.menu-btn-style {
            span {
              text-decoration: none;
              background-color: $avg-grey;
              @include border-radius(7px);
              display: inline-block;
              padding: 10px 20px;
              color: #000000;
              font-weight: bold;

              //letter-spacing: 1.5px;
              &:hover {
                cursor: pointer;
                color: $intact-red;
              }
            }
          }

          &:last-child {
            margin: 0 0 0 10px;
          }
        }
      }

      .search-form {
        position: relative;
        margin: 0 20px 0 20px;
        width: 25%;

        .search-icon {
          position: absolute;
          @include translate(0, -50%);
          top: 50%;
          left: 15px;
        }

        .search-input {
          padding: 10px 10px 10px 50px;
          width: 100%;
          @include transition(all 0.25s linear);
          border: 1px solid $light-grey;

          &:focus,
          &:hover {
            @include border-radius-separate(25px, 5px, 5px, 5px);
            outline: none;
            box-shadow: none;
          }
        }
      }

      .profil-btn,
      .admin-btn,
      .lang-btn {
        background-color: #343434;
        text-align: center;
        @include border-radius(7px);
        @include transition(all 0.25s linear);

        a {
          display: block;
          color: #fff;
          text-decoration: none;
          padding: 10px 15px;

          .profile-icon-cnt {
            width: 30px;
            display: inline-block;
            text-align: center;
            margin: 0 auto;

            .profile-icon {
              //margin: 0 auto;
            }
          }

          .profile-name {
            margin: 0 auto;
            padding: 0 10px;

            @media #{$lg-device} {
              display: none;
            }
          }
        }

        &:hover {
          background-color: $intact-red;

          a {
            color: #fff;
          }
        }
      }

      .lang-btn {
        background-color: $intact-blue;
        margin-left: 15px;
        font-weight: bold;
      }

      .profil-btn {
        background-color: #7c7c7c;

        a {
          color: #fff;
          padding: 10px;
        }
      }

      .certificate-btn {
        background-color: #7c7c7c;
        text-align: center;
        @include border-radius(7px);
        @include transition(all 0.25s linear);

        width: 50px;
        height: 44px;
        margin: 0 15px;

        a {
          display: block;
          color: #fff;
          text-decoration: none;
          padding: 10px;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        &:hover {
          background-color: $intact-red;

          a {
            color: #fff;
          }
        }
      }

      .admin-btn {
        background-color: #001529;
        margin-left: 15px;

        a {
          color: #fff;
          padding: 10px;
        }
      }


      .menu-greetings {
        text-align: right;
        padding: 10px 5px 0 15px;
        margin: 0;
      }
    }

    .menu-mobile {
      position: absolute;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      display: none;
      @include translate(-100%, 0);
      @include transition(all 0.25s);

      @media #{$md-device} {
        display: flex;
      }

      justify-content: center;
      align-items: center;
      background-color: #fff;
      z-index: 999;

      ul {
        list-style: none;
        padding: 0;

        li {
          text-align: center;

          a,
          span {
            text-decoration: none;
            color: $intact-red;
            font-weight: bold;
            font-size: 35px;

            &:hover {
              cursor: pointer;
            }
          }

          &.menu-btn-lang-switch {
            background-color: $intact-red;
            @include border-radius(7px);
            @include transition(all 0.25s linear);
            width: 80px;
            margin: 0 auto;

            a,
            span {
              color: #fff;
              font-size: 25px;
            }
          }
        }
      }

      &.menu-mobile-open {
        left: 100%;
      }
    }
  }
}


/*************************************************/

.ant-layout {
  min-height: 100vh !important;

  .ant-layout-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.demo-loadmore-list {
  min-height: 350px;
}

.ant-table-content {
  td {
    min-width: 45px;
    overflow: hidden;
  }
}

.notification-container {
  width: auto !important;

  .notification-warning {
    background-color: $intact-red;
    opacity: 1 !important;
    width: 600px;
    font-size: 50px;
  }

  .notification-error {
    background-color: $intact-red;
    opacity: 1 !important;
    width: 600px;
    font-size: 30px;
  }

  .notification-info {
    background-color: $intact-blue;
    opacity: 1 !important;
  }

}


.lang-btn-login {
  background-color: $intact-blue;
  margin-left: 15px;
  text-align: center;
  @include border-radius(7px);
  @include transition(all 0.25s linear);
  position: absolute;
  top: 10px;
  right: 10px;

  a {
    display: block;
    color: #fff;
    text-decoration: none;
    padding: 10px 15px;

    .profile-icon-cnt {
      width: 30px;
      display: inline-block;
      text-align: center;
      margin: 0 auto;

      .profile-icon {
        //margin: 0 auto;
      }
    }

    .profile-name {
      margin: 0 auto;
      padding: 0 10px;
    }
  }

  &:hover {
    background-color: $intact-red;

    a {
      color: #fff;
    }
  }
}


.btn-info-cnt {
  text-align: center;

  .btn-info {
    background-color: $intact-red;
    padding: 10px 20px;
    font-family: "Poppins SemiBold";
    border: 0;
    border-radius: 5px;
    font-size: 25px;
    @include transition(all 0.25s linear);

    svg {
      margin: 0 10px 0 7px;
    }

    &:hover {
      //@include box-shadow(2px, 2px, 5px, 3px, rgba(201,201,201,0.5));
      @include border-radius-separate(15px, 5px, 5px, 5px);
    }

    @media #{$xs-device} {
      margin: 0 auto;
    }
  }
}



/*********menu-right-alt*********************/
.gg-menu-right-alt {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 16px;
  height: 14px;
  transform: scale(var(--ggs, 1))
}

.gg-menu-right-alt::after,
.gg-menu-right-alt::before {
  content: "";
  position: absolute;
  box-sizing: border-box;
  display: block;
  height: 2px;
  border-radius: 4px;
  background: currentColor
}

.gg-menu-right-alt::before {
  width: 16px;
  box-shadow: 0 12px 0
}

.gg-menu-right-alt::after {
  width: 10px;
  right: 0;
  top: 6px
}